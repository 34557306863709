var blocks = document.querySelectorAll('.js-scroll-section');

window.addEventListener('scroll', () => {
   if (document.getElementById('parallax-container')) {
   	let parent = document.getElementById('parallax-container');
   	let children = document.getElementById('top-layer-parallax');
   	//children.style.transform = 'translateY(-' + (window.pageYOffset * 5) + 'px)';
   	children.style.transform = 'translateY(-' + (window.pageYOffset) + 'px)';
   	//console.log(100 - window.pageYOffset);
   	/*let opacityNum = 100 - window.pageYOffset;

   	if ( opacityNum > 1 ) {
   		children.style.opacity = opacityNum * 0.01;
   		//console.log(opacityNum * 0.01);
   	}*/
   }

   for(let i = 0; i < blocks.length; i++) {
      if (isInViewport(blocks[i])) {
         //console.log(blocks[i]);
         if ( document.querySelector('.sec-' + blocks[i].getAttribute('id')) ) {
            //console.log(blocks[i].getAttribute('id'));
            var test = document.querySelector('.sec-' + blocks[i].getAttribute('id'));
            test.classList.add('active');
         }
         //console.log(blocks[i]);
      } else {
         if ( document.querySelector('.sec-' + blocks[i].getAttribute('id')) ) {
            var test = document.querySelector('.sec-' + blocks[i].getAttribute('id'));
            test.classList.remove('active');
         }
      }
   };
}, false)

var isInViewport = function (elem) {
    var bounding = elem.getBoundingClientRect();
    //console.log(bounding.bottom);
    return (
        bounding.top <= 88 &&
        bounding.bottom >= 88
    );
};

// Grab all the scroll class anchor elements, use whatever class you like
const scrollElems = document.querySelectorAll('.js-scroll-nav');
// Now add an event listeners to those element
for(let i = 0; i < scrollElems.length; i++){
   const elem = scrollElems[i];
 
   elem.addEventListener('click',function(e) {
    e.preventDefault();
    
    // 1. Get the element id to which you want to scroll
    const scrollElemId = e.target.href.split('#')[1];
    
    // 2. find that node from the document
    const scrollEndElem = document.getElementById(scrollElemId);
    
    // 3. and well animate to that node.. 
    const anim = requestAnimationFrame((timestamp) => {
      const stamp = timestamp || new Date().getTime();
      const duration = 1200;
      const start = stamp;
   
      const startScrollOffset = window.pageYOffset;
      const scrollEndElemTop = scrollEndElem.getBoundingClientRect().top;
   
      scrollToElem(start, stamp, duration, scrollEndElemTop, startScrollOffset);
    })
  })
}

// Lets ignore it for the moment.
const easeInCubic = function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
 
const scrollToElem = (startTime, currentTime, duration, scrollEndElemTop, startScrollOffset) => {
   const runtime = currentTime - startTime;
   let progress = runtime / duration;
   const menuHolder = document.getElementById('menu-holder');
   
   progress = Math.min(progress, 1);
   
   const ease = easeInCubic(progress);
   
   window.scroll(0, startScrollOffset + (scrollEndElemTop * ease));
if(runtime < duration){
     requestAnimationFrame((timestamp) => {
       const currentTime = timestamp || new Date().getTime();
       scrollToElem(startTime, currentTime, duration, scrollEndElemTop, startScrollOffset);
       if ( menuHolder.classList.contains('visible') ) {
        menuHolder.classList.remove('visible');
        mobileMenu.innerHTML = 'Navigera';
    }
     })
   }
 }

const mobileMenu = document.getElementById('mobile-menu');

mobileMenu.addEventListener('click',function(e) {
    e.preventDefault();

    const menuHolder = document.getElementById('menu-holder');

    if ( menuHolder.classList.contains('visible') ) {
        menuHolder.classList.remove('visible');
        mobileMenu.innerHTML = 'Navigera';
    } else {
        menuHolder.classList.add('visible');
        mobileMenu.innerHTML = 'Stäng';
    }
});